import Typography from '../Typography';
import OptionsField from '../fields/OptionsField';
import * as Formatter from '../../utils/FormatterUtil.js';
import { useCallback, useMemo } from 'react';
import RangeField from '@/components/fields/RangeField';
import RequestAmountField from '@/components/fields/RequestAmountField.jsx';

const ApplicationWidgetGeneralForm = ({ data, setData, errors, presets, ...properties }) => {
    const options = {
        application_days: presets.application_days.map((application_day) => ({
            title: Formatter.requestedAmountNeededIn(application_day),
            value: application_day,
        })),
        owns_collateral_objects: [
            { title: 'Ik huur', value: false },
            { title: 'Ik heb gekocht', value: true },
        ],
    };

    const handleFormChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            setData((previousData) => ({ ...previousData, [name]: value }));
        },
        [setData],
    );

    return (
        <form {...properties}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Wat is het gewenste kredietbedrag?</Typography>

                    <RequestAmountField name={'requested_amount'} value={data?.requested_amount} onChange={handleFormChange} />
                    <RangeField name={'requested_amount'} value={data?.requested_amount} onChange={handleFormChange} />

                    {errors?.requested_amount && <Typography color={'error.base'}>{errors.requested_amount}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <p>Wanneer heeft u het nodig?</p>

                    <OptionsField name={'requested_amount_needed_in'} value={data?.requested_amount_needed_in} options={options.application_days} onChange={handleFormChange} />

                    {errors?.requested_amount_needed_in && <Typography color={'error.base'}>{errors.requested_amount_needed_in}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <p>Huurt u momenteel privé een woning of heeft u privé een woning gekocht?</p>

                    <OptionsField name={'owns_collateral_objects'} value={data?.owns_collateral_objects} options={options.owns_collateral_objects} onChange={handleFormChange} />

                    {errors?.owns_collateral_objects && <Typography color={'error.base'}>{errors.owns_collateral_objects}</Typography>}
                </div>
            </div>
        </form>
    );
};

export default ApplicationWidgetGeneralForm;
