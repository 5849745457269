import Button from '@/components/Button.jsx';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import ApplicationWidgetConfig from '@/configs/application-widget.config.js';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import ApplicationWidgetCollateralObjectForm from '@/components/forms/ApplicationWidgetCollateralObjectForm.jsx';

const ApplicationWidget = ({ className, presets, source, defaultValues, referer }) => {
    const [steps, setSteps] = useState(ApplicationWidgetConfig.steps);
    const [data, setData] = useState({
        step: 0,
        requested_amount: 50000,
        owns_collateral_objects: true,
        has_multiple_collateral_objects: false,
        requested_amount_needed_in: presets.application_days[0],
        user: {
            salutation: 'mr',
        },
        referer,
        source,
        ...defaultValues,
    });
    const [isSuccesfully, setSuccesfully] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!data.owns_collateral_objects) {
            setSteps((previousSteps) => previousSteps.filter((step) => step.Form !== ApplicationWidgetCollateralObjectForm));
        } else {
            setSteps(ApplicationWidgetConfig.steps);
        }
    }, [data]);

    const nextStep = async () => {
        try {
            setLoading(true);
            await axios.post(route('widget.store'), data);

            const hastNext = data?.step < steps.length - 1;

            if (hastNext) {
                setErrors({});
                setData((previousData) => ({ ...previousData, step: previousData?.step + 1 }));
            } else {
                if (source.data?.redirect_url) {
                    window.top.location.href = source.data.redirect_url;
                }

                setSuccesfully(true);
            }
        } catch (error) {
            setErrors(error?.response?.data?.errors || {});
        } finally {
            setLoading(false);
        }
    };

    const previousStep = () => {
        setData((previousData) => ({ ...previousData, step: previousData?.step - 1 }));
    };

    return (
        <div className={twMerge('relative flex flex-col bg-white rounded-lg border border-solid border-grey-200 h-[740px] overflow-hidden', className)}>
            <LoadingOverlay loading={isLoading} />

            <div className={'flex h-24 w-full items-center justify-between gap-2 border-0 border-b border-solid border-grey-200 bg-grey-100 px-5'}>
                {steps.map((step, stepIdx) => {
                    const isActive = data?.step >= stepIdx;
                    const hastNext = stepIdx < steps.length - 1;

                    return (
                        <>
                            <div key={`indicator-indicator-${stepIdx}`} className={'hexagon relative aspect-square min-w-16 bg-grey-200'}>
                                <AnimatePresence initial={false}>{isActive && <motion.div className={'absolute left-0 top-0 size-full bg-primary-500'} initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} />}</AnimatePresence>
                            </div>

                            {hastNext && <div key={`indicator-line-${stepIdx}`} className={'h-px w-full bg-grey-150'}></div>}
                        </>
                    );
                })}
            </div>

            <div className={'h-[548px] overflow-auto px-5 py-10'}>
                {isSuccesfully ? (
                    <div className={'flex h-full flex-col items-center justify-center gap-2 px-10'}>
                        <p className={'text-center text-2xl font-bold'}>Bedankt voor uw aanvraag!</p>

                        <p className={'text-center'}>Wij hebben uw aanvraag ontvangen en nemen binnen 48 uur persoonlijk contact met u op om de mogelijkheden te bespreken.</p>
                    </div>
                ) : (
                    <>
                        {steps.map((step, stepIdx) => {
                            const isActive = data?.step === stepIdx;
                            const Form = step.Form;

                            if (!isActive) return;

                            return (
                                <div key={`step-${stepIdx}`} className={'flex flex-col gap-5'}>
                                    <div className={'flex flex-col gap-1'}>
                                        <h1 className={'text-lg font-bold'}>{step.title}</h1>

                                        <p className={'text-grey-900'}>{step.description}</p>
                                    </div>

                                    <Form data={data} setData={setData} errors={errors} presets={presets} onSubmit={nextStep} />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>

            <div className={'flex h-24 items-center justify-between gap-5 border-0 border-t border-solid border-grey-200 bg-grey-100 px-5'}>
                {!isSuccesfully && (
                    <>
                        <Button className={'w-full border border-solid border-grey-900 bg-transparent text-black'} onClick={previousStep} disabled={data?.step === 0}>
                            <ArrowLeftIcon className={'aspect-square w-4'} />
                            <span>Vorige</span>
                        </Button>

                        <Button className={'w-full'} onClick={nextStep}>
                            <span>Volgende</span>
                            <ArrowRightIcon className={'aspect-square w-4'} />
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ApplicationWidget;
